import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import en from "vuetify/lib/locale/en";

// Translation provided by Vuetify (javascript)
import fr from "vuetify/lib/locale/fr";

export default new Vuetify({
  theme: { dark: true },
  lang: {
    locales: {
      en,
      fr,
    },
    current: "en",
  },
});
