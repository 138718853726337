import Vue from "vue";
import Router from "vue-router";

// Import PAGES
import Home from "../components/Home";
import PageNotFound from "../components/PageNotFound";
// English Pages
import en_WhoAmI from "../components/WhoAmI";
import en_Projects from "../components/Projects";
import en_ContactMe from "../components/ContactMe";
import en_ProfessionalExperiences from "../components/ProfessionalExperiences";
import en_ProjectList from "../components/ProjectList";

// French Pages
// import fr_AboutUs from "../components/fr/AboutUs.vue";
// import fr_News from "../components/fr/News.vue";
// import fr_Help from "../components/fr/Help.vue";

Vue.use(Router);

export default new Router({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "",
      name: "home",
      component: Home,
    },
    {
      path: "/",
      name: "shome",
      component: Home,
    },
    // ENGLISH PAGES
    {
      path: "/whoami",
      name: "en_WhoAmI",
      component: en_WhoAmI,
    },
    {
      path: "/projects",
      name: "en_Projects",
      component: en_Projects,
    },
    {
      path: "/contact",
      name: "en_ContactMe",
      component: en_ContactMe,
    },
    {
      path: "/professionalexperiences",
      name: "en_ProfessionalExperiences",
      component: en_ProfessionalExperiences,
    },
    {
      path: "/projectlist",
      name: "en_ProjectList",
      component: en_ProjectList,
    },

    // // FRENCH PAGES
    // {
    //   path: "/fr/about-us",
    //   name: "fr_about-us",
    //   component: fr_AboutUs,
    // },
    // {
    //   path: "/fr/news",
    //   name: "fr_news",
    //   component: fr_News,
    // },
    // {
    //   path: "/fr/help",
    //   name: "fr_help",
    //   component: fr_Help,
    // },

    // // ToolLoaderPages
    // {
    //   path: toolLoaderRoutes[0],
    //   name: "toolLoader",
    //   component: ToolLoader,
    //   alias: toolLoaderRoutes,
    // },

    { path: "*", component: PageNotFound },
  ],
});
