<template>
  <v-app>
    <div class="bg-purple">
      <div class="stars">
        <div class="central-body">
          <img
            class="image-404"
            :src="
              dirname +
                'images/NotFoundPage/' +
                this.$vuetify.lang.current +
                '404.svg'
            "
            width="300px"
          />
          <div></div>
          <v-btn to="/" class="ma-2" outlined color="yellow">
            {{ btnText }}
          </v-btn>
        </div>
        <div class="objects">
          <img
            class="object_rocket"
            :src="dirname + 'images/NotFoundPage/rocket.svg'"
            width="40px"
          />
          <div class="earth-moon">
            <img
              class="object_earth spin-earth-on-hover"
              :src="dirname + 'images/NotFoundPage/earth.svg'"
              width="100px"
            />
            <img
              class="object_moon"
              :src="dirname + 'images/NotFoundPage/moon.svg'"
              width="80px"
            />
          </div>
          <div class="box_astronaut">
            <img
              class="object_astronaut"
              :src="dirname + 'images/NotFoundPage/astronaut.svg'"
              width="140px"
            />
          </div>
        </div>
        <div class="glowing_stars">
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
          <div class="star"></div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "pageNotFoundComponent",

  components: {},

  data: () => ({
    dirname: "",
    btnText: "Go Home",
  }),
  /**
   * @method created
   * Launches when the component is loaded
   */
  created() {
    this.pageNotFoundLang();
  },
  methods: {
    /**
   * @function pageNotFoundLang
   * Change the language of the button
   */
    pageNotFoundLang() {
      if (this.$vuetify.lang.current == "fr") {
        this.btnText = "Accueil";
      } else {
        this.btnText = "Go Home";
      }
    },
  },
  /**
   * @method watch_vuetify_lang_current
   * if the global variable $vuetify.lang.current changes, it starts pageNotFoundLang
   */
  watch: {
    "$vuetify.lang.current": function() {
      this.pageNotFoundLang();
    },
  },
};
</script>

<style lang="scss">
@import "~/src/scss/pageNotFound.scss";
</style>
