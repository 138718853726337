<template>
  <v-app style="background-color: #312450">
    <v-btn
      class="mt-16 mr-16"
      fab
      style="position: fixed; right: 0; z-index: 100"
      x-small
      to="/projects"
    >
      <v-icon x-large color="red">fa-times-circle</v-icon>
    </v-btn>
    <v-container class="mb-16">
      <v-row align="center" justify="center">
        <v-col cols="12" md="12" lg="11" class="mt-8">
          <h2 class="text-h2 mb-8 major">My projects :</h2>
          <v-autocomplete
            auto-select-first
            chips
            clearable
            deletable-chips
            multiple
            solo
            light
            color="red"
            label="Search Bar"
            v-model="values"
            :items="items"
          ></v-autocomplete>
          <v-item-group>
            <v-container>
              <v-row>
                <v-col
                  v-for="proj in filteredtradData"
                  :key="proj.id"
                  cols="12"
                  md="6"
                >
                  <v-item>
                    <v-card
                      class="d-flex align-left flex-column"
                      outlined
                      height="600"
                      style="background-color: #b74e91"
                      elevation="4"
                    >
                      <v-carousel
                        cycle
                        height="500"
                        hide-delimiter-background
                        show-arrows-on-hover
                      >
                        <v-carousel-item
                          v-for="imgID in parseInt(proj.imagenum)"
                          :key="imgID"
                          :src="
                            dirname +
                            'images/Projects/' +
                            removeAccents(proj.title) +
                            '/' +
                            imgID +
                            '.jpg'
                          "
                        >
                        </v-carousel-item>
                      </v-carousel>
                      <v-card-title> {{ proj.title }} </v-card-title>
                      <v-card-actions>
                        <v-btn
                          color="orange lighten-2"
                          @click="proj.show = !proj.show"
                          text
                        >
                          Explore
                        </v-btn>

                        <v-spacer></v-spacer>

                        <v-btn icon @click="proj.show = !proj.show">
                          <v-icon>{{
                            proj.show ? "mdi-chevron-up" : "mdi-chevron-down"
                          }}</v-icon>
                        </v-btn>
                      </v-card-actions>
                      <v-expand-transition>
                        <div v-show="proj.show">
                          <v-divider></v-divider>

                          <v-card-text>
                            <p>
                              <b>Description:</b> {{ proj.desc }}<br />

                              <b v-if="proj.links != null">Links:</b>
                              <a
                                style="
                                  color: rgba(255, 255, 255, 0.55) !important;
                                "
                                v-for="(link, index) in proj.links"
                                :key="index"
                                :href="link.href"
                                target="_blank"
                              >
                                {{ link.text }}</a
                              >
                              <br v-if="proj.links != null" />

                              <b>Technology Used:</b> {{ proj.techused }}<br />

                              <b>Project Duration:</b> {{ proj.projdur }}<br />

                              <b>My contribution:</b> {{ proj.contrib }}<br />
                            </p>
                          </v-card-text>
                        </div>
                      </v-expand-transition>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
import projectList from "../models/projectList.json";
export default {
  name: "projectsLists-component",

  data: () => ({
    dirname: "",
    tradData: projectList.projects,
    values: [],
    items: projectList.keyWords,
  }),

  computed: {
    filteredtradData: function () {
      if (this.values == null || this.values.length == 0) return this.tradData;

      let returnData = [];

      this.tradData.forEach((proj) => {
        let containsAll = true;
        this.values.forEach((value) => {
          if (
            !proj.KeyWords.includes(value) &&
            !proj.techused.includes(value)
          ) {
            containsAll = false;
          }
        });

        if (containsAll) returnData.push(proj);
      });
      return returnData;
    },
  },
  methods: {
    presetFilter: function () {
      const queryString = window.location.hash;
      if (queryString.includes("filter=games")) {
        this.values.push(this.items[0]);
      }
      if (queryString.includes("filter=school"))
        this.values.push(this.items[1]);
    },
    removeAccents: function (str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
  },

  created() {
    if (process.env.NODE_ENV == "development") this.dirname = "/";
    this.presetFilter();
  },
};
</script>

<style lang="scss">
</style>
