<template>
  <v-app style="background-color: #b74e91">
    <v-container class="mb-16">
      <v-row align="center" justify="center">
        <v-col cols="12" md="12" lg="11" class="mt-16">
          <h2 class="text-h3 mb-8">Who am I ?</h2>
          <p style="color: rgba(255, 255, 255, 0.55)">
            I’m a French fourth year student in game development at the UQAC
            (University of Quebec in Chicoutimi). <br />
            During my studies, I realized a lot of various project and games
            listed here.<br />
            My next studies offers me the opportunity to do an apprenticeship in
            a company for july 2022. <br />
            If you’re interested, you can contact me via this form.
          </p>
          <v-item-group>
            <v-container>
              <v-row>
                <!-- C++ card -->
                <v-col cols="12" md="4">
                  <v-item>
                    <v-card
                      class="d-flex align-left flex-column progressBarCard"
                      outlined
                      height="300"
                      style="background-color: #b74e91"
                      elevation="4"
                    >
                      <v-card-title style="display: block !important"
                        ><v-icon class="mr-2">
                          icon solid major fa-code
                        </v-icon>
                        Object oriented programming :
                      </v-card-title>
                      <v-col
                        cols="12"
                        md="10"
                        offset-md="1"
                        style="color: rgba(255, 255, 255, 0.55)"
                      >
                        C++ :
                        <v-progress-linear
                          color="white accent-4"
                          rounded
                          height="25"
                          __value="90"
                        >
                          <strong class="black--text text--lighten-1"
                            >90%</strong
                          >
                        </v-progress-linear>
                        <p>C# :</p>
                        <v-progress-linear
                          color="white accent-4"
                          rounded
                          height="25"
                          __value="90"
                        >
                          <strong class="black--text text--lighten-1"
                            >90%</strong
                          >
                        </v-progress-linear>

                        JAVA :
                        <v-progress-linear
                          color="white accent-4"
                          rounded
                          height="25"
                          __value="70"
                        >
                          <strong class="black--text text--lighten-1"
                            >70%</strong
                          >
                        </v-progress-linear>
                      </v-col>
                    </v-card>
                  </v-item>
                </v-col>
                <!-- C# card -->
                <v-col cols="12" md="4">
                  <v-item>
                    <v-card
                      class="d-flex align-left flex-column progressBarCard"
                      outlined
                      height="300"
                      style="background-color: #b74e91"
                      elevation="4"
                    >
                      <v-card-title style="display: block !important"
                        ><v-icon class="mr-2">
                          icon solid major fa-gamepad
                        </v-icon>
                        Gaming engines :
                      </v-card-title>
                      <v-col
                        cols="12"
                        md="10"
                        offset-md="1"
                        style="color: rgba(255, 255, 255, 0.55)"
                      >
                        Unity :
                        <v-progress-linear
                          color="white accent-4"
                          rounded
                          height="25"
                          __value="90"
                        >
                          <strong class="black--text text--lighten-1"
                            >90%</strong
                          >
                        </v-progress-linear>
                        <p>Unreal :</p>
                        <v-progress-linear
                          color="white accent-4"
                          rounded
                          height="25"
                          __value="80"
                        >
                          <strong class="black--text text--lighten-1"
                            >80%</strong
                          >
                        </v-progress-linear>
                      </v-col>
                    </v-card>
                  </v-item>
                </v-col>

                <!-- Tools and methods card -->
                <v-col cols="12" md="4">
                  <v-item>
                    <v-card
                      class="d-flex align-left flex-column progressBarCard"
                      outlined
                      height="300"
                      style="background-color: #b74e91"
                      elevation="4"
                    >
                      <v-card-title
                        style="display: block !important"
                        class="pb-1"
                        ><v-icon class="mr-2">
                          icon solid major fa-wrench
                        </v-icon>
                        Tools and methods :
                      </v-card-title>
                      <v-col
                        cols="12"
                        md="10"
                        offset-md="1"
                        style="color: rgba(255, 255, 255, 0.55)"
                      >
                        <u>Conception :</u> <br />
                        Scrum, UML (Merise), WireframeSketcher, PowerAMC.
                        <u>Development :</u> <br />
                        Microsoft Visual Studio, VS Code, GIT, Perforce,
                        Jetbrains Tools
                      </v-col>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
              <v-row>
                <!-- Web card -->
                <v-col cols="12" md="4">
                  <v-item>
                    <v-card
                      class="d-flex align-left flex-column progressBarCard"
                      outlined
                      height="300"
                      style="background-color: #b74e91"
                      elevation="4"
                    >
                      <v-card-title style="display: block !important"
                        ><v-icon class="mr-2">
                          icon solid major fa-window-maximize
                        </v-icon>
                        Web :
                      </v-card-title>
                      <v-col
                        cols="12"
                        md="10"
                        offset-md="1"
                        style="color: rgba(255, 255, 255, 0.55)"
                      >
                        HTML / CSS :
                        <v-progress-linear
                          color="white accent-4"
                          rounded
                          height="25"
                          __value="70"
                        >
                          <strong class="black--text text--lighten-1"
                            >70%</strong
                          >
                        </v-progress-linear>
                        <p>JS :</p>
                        <v-progress-linear
                          color="white accent-4"
                          rounded
                          height="25"
                          __value="90"
                        >
                          <strong class="black--text text--lighten-1"
                            >90%</strong
                          >
                        </v-progress-linear>

                        PHP :
                        <v-progress-linear
                          color="white accent-4"
                          rounded
                          height="25"
                          __value="60"
                        >
                          <strong class="black--text text--lighten-1"
                            >60%</strong
                          >
                        </v-progress-linear>
                      </v-col>
                    </v-card>
                  </v-item>
                </v-col>

                <!-- Database card -->
                <v-col cols="12" md="4">
                  <v-item>
                    <v-card
                      class="d-flex align-left flex-column progressBarCard"
                      outlined
                      height="300"
                      style="background-color: #b74e91"
                      elevation="4"
                    >
                      <v-card-title style="display: block !important"
                        ><v-icon class="mr-2">
                          icon solid major fa-server
                        </v-icon>
                        Database :
                      </v-card-title>
                      <v-col
                        cols="12"
                        md="10"
                        offset-md="1"
                        style="color: rgba(255, 255, 255, 0.55)"
                      >
                        Microsoft SQL Server :
                        <v-progress-linear
                          color="white accent-4"
                          rounded
                          height="25"
                          __value="80"
                        >
                          <strong class="black--text text--lighten-1"
                            >80%</strong
                          >
                        </v-progress-linear>
                        <p>Oracle Database :</p>
                        <v-progress-linear
                          color="white accent-4"
                          rounded
                          height="25"
                          __value="60"
                        >
                          <strong class="black--text text--lighten-1"
                            >60%</strong
                          >
                        </v-progress-linear>

                        PostgreSQL :
                        <v-progress-linear
                          color="white accent-4"
                          rounded
                          height="25"
                          __value="50"
                        >
                          <strong class="black--text text--lighten-1"
                            >50%</strong
                          >
                        </v-progress-linear>
                      </v-col>
                    </v-card>
                  </v-item>
                </v-col>
                <!-- Language card -->
                <v-col cols="12" md="4">
                  <v-item>
                    <v-card
                      class="d-flex align-left flex-column progressBarCard"
                      outlined
                      height="300"
                      style="background-color: #b74e91"
                      elevation="4"
                    >
                      <v-card-title
                        style="display: block !important"
                        class="pb-1"
                        ><v-icon class="mr-2">
                          icon solid major fa-flag
                        </v-icon>
                        Languages
                      </v-card-title>
                      <v-col
                        cols="12"
                        md="10"
                        offset-md="1"
                        style="color: rgba(255, 255, 255, 0.55)"
                      >
                        <u>French :</u> Native. <br />

                        <u>English :</u> B2 ( TOEIC : 860/990)<br />
                        <u>Spanish :</u> Notions<br />
                      </v-col>
                    </v-card>
                  </v-item>
                </v-col>
              </v-row>
            </v-container>
          </v-item-group>

          <v-card-title
            style="display: block !important"
            class="pb-1 text-h3 mb-8"
            ><v-icon class="mr-2"> icon solid major fa-flag </v-icon>
            About my studies :
          </v-card-title>
          Click on a diploma to see its description.

          <v-timeline :dense="$vuetify.breakpoint.smAndDown" class="mb-16">
            <v-timeline-item color="purple lighten-2" fill-dot right>
              <v-card @click="firstTL = !firstTL">
                <v-card-title class="purple lighten-2 pa-2">
                  <div class="mt-2">2016<br />2018</div>
                  <h2 class="text-h4 white--text font-weight-light ml-2">
                    - BAC STI2D-SIN
                  </h2>
                </v-card-title>
                <v-expand-transition>
                  <v-container v-show="firstTL" class="TLdetailsShowAnim">
                    <v-row>
                      <v-col>
                        High School diploma specialized in computer engineering
                        with high honors and European English mention <br />
                        Lachenal High school – Argonay, France
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expand-transition>
              </v-card>
            </v-timeline-item>

            <v-timeline-item color="amber lighten-1" fill-dot left small>
              <v-card @click="secondTL = !secondTL">
                <v-card-title class="amber lighten-1 pa-2 justify-end">
                  <h2 class="text-h4 white--text font-weight-light mr-2">
                    DUT Informatique -
                  </h2>
                  <div class="mt-2">2018<br />2020</div>
                </v-card-title>
                <v-expand-transition>
                  <v-container v-show="secondTL">
                    <v-row>
                      <v-col cols="12" md="10">
                        A two-year university diploma in computer science <br />
                        University of Savoy / IUT Annecy <br />
                        Annecy-Le-Vieux, France
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expand-transition>
              </v-card>
            </v-timeline-item>

            <v-timeline-item color="cyan lighten-1" fill-dot right>
              <v-card @click="thirdTL = !thirdTL">
                <v-card-title class="cyan lighten-1 pa-2">
                  <div class="mt-2">2020<br />2021</div>
                  <h2 class="text-h4 white--text font-weight-light ml-2">
                    - Baccalaureate with video game major
                  </h2>
                </v-card-title>
                <v-expand-transition>
                  <v-container v-show="thirdTL">
                    <v-row>
                      <v-col>
                        A three-year university diploma in computer science
                        specialized in video games developpement <br />
                        University of Quebec at Chicoutimi / UQAC <br />
                        Chicoutimi, Canada <br />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expand-transition>
              </v-card>
            </v-timeline-item>

            <v-timeline-item color="red lighten-1" fill-dot left small>
              <v-card @click="fourthTL = !fourthTL">
                <v-card-title class="red lighten-1 pa-2 justify-end">
                  <h2 class="text-h4 white--text font-weight-light mr-2">
                    Master's degree in computer <br />
                    science (video games)
                  </h2>
                  <h2 class="mr-2">-</h2>
                  <div class="mt-2">2021<br />2022</div>
                </v-card-title>
                <v-expand-transition>
                  <v-container v-show="fourthTL">
                    <v-row>
                      <v-col>
                        A two-year master's degree in computer science
                        specialized in video game development. <br />
                        University of Quebec at Chicoutimi / UQAC <br />
                        Chicoutimi, Canada <br />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expand-transition>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import scrollManager from "../plugins/scrollManager";
export default {
  name: "whoami-component",
  mixins: [scrollManager],

  data: () => ({
    dirname: "",
    traductionData: undefined,
    firstTL: false,
    secondTL: false,
    thirdTL: false,
    fourthTL: false,
    upperPage: "/",
    lowerPage: "/projects",
  }),

  created() {
    if (process.env.NODE_ENV == "development") this.dirname = "/";
  },

  mounted() {
    this.animateProgessBars();
  },

  methods: {
    animateProgessBars() {
      var progressBars = document.getElementsByClassName(
        "v-progress-linear__determinate"
      );

      progressBars.forEach((progressBar) => {
        setTimeout(
          () =>
            (progressBar.style.width =
              progressBar.parentElement.getAttribute("__value") + "%"),
          500
        );
      });
    },
  },
};
</script>

<style lang="scss">
.icon.major {
  width: 2em;
  height: 2em;
  background: #ffffff;
  border-radius: 100%;
  color: #312450;
  text-align: center;
  line-height: 2em;
}

.icon.major:before {
  color: #b74e91;
}

.icon.major:before {
  font-size: 1.25em;
}
.icon.solid:before {
  font-weight: 900;
}
.v-progress-linear__background {
  left: 0% !important;
  width: 100% !important;
}
.v-progress-linear__content {
  transition: 1s all ease !important;
}

.v-application .progressBarCard p {
  margin-bottom: 0px;
}

.TLdetailsShowAnim {
  transition: 1s all ease !important;
}
</style>
