<template>
  <v-app style="background-color: #5052b5">
    <v-container width="1080px" class="mb-16">
      <v-row>
        <v-col style="height: 360px" cols="12" md="4" class="pa-0">
          <v-img
            :src="dirname + 'images/pic01.jpg'"
            height="360px"
            width="100%"
          ></v-img>
        </v-col>
        <v-col style="height: 360px" cols="12" md="8">
          <v-card width="100%" height="100%" color="transparent" outlined>
            <v-card-text class="ml-md-16 pt-md-8 text-body-1">
              <v-card-title class="text-h3 pl-0 white--text">
                Video games
              </v-card-title>
              During my studies, I had the chance to realise many small video
              games with different technologies: <br />
              Unity, Unreal or even just a graphic library.
            </v-card-text>
            <v-card-actions class="ml-16">
              <v-btn to="/projectlist?filter=games" outlined rounded>
                Learn More</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col style="height: 360px" cols="12" md="4" class="pa-0">
          <v-img
            :src="dirname + 'images/pic02.jpg'"
            height="360px"
            width="100%"
          ></v-img>
        </v-col>
        <v-col
          style="height: 360px; background-color: rgba(0, 0, 0, 0.05)"
          cols="12"
          md="8"
        >
          <v-card width="100%" height="100%" color="transparent" outlined>
            <v-card-text class="ml-md-16 pt-md-8 text-body-1">
              <v-card-title class="text-h3 pl-0 white--text">
                Professional experiences
              </v-card-title>
              I have currently three professional experiences and two of them
              are linked to development. I mainly worked as a software
              developper but I've also made some websites and I worked as a team
              leader.
            </v-card-text>
            <v-card-actions class="ml-16">
              <v-btn to="/professionalexperiences" outlined rounded>
                Learn More</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col style="height: 360px" cols="12" md="4" class="pa-0">
          <v-img
            :src="dirname + 'images/pic03.jpg'"
            height="360px"
            width="100%"
          ></v-img>
        </v-col>
        <v-col
          style="height: 360px; background-color: rgba(0, 0, 0, 0.1)"
          cols="12"
          md="8"
        >
          <v-card width="100%" height="100%" color="transparent" outlined>
            <v-card-text class="ml-md-16 pt-md-8 text-body-1">
              <v-card-title class="text-h3 pl-0 white--text">
                Other school project
              </v-card-title>
              I've made many school related project listed here.
            </v-card-text>
            <v-card-actions class="ml-16">
              <v-btn to="/projectlist?filter=school" outlined rounded>
                Learn More</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import scrollManager from "../plugins/scrollManager";
export default {
  name: "projects-component",
  mixins: [scrollManager],

  data: () => ({
    dirname: "",
    traductionData: undefined,
    firstTL: false,
    secondTL: false,
    thirdTL: false,
    fourthTL: false,
    upperPage: "/whoami",
    lowerPage: "/contact",
  }),

  created() {
    if (process.env.NODE_ENV == "development") this.dirname = "/";
  },
};
</script>

<style lang="scss">
</style>
