export default {
  name: "scrollManager",

  data: () => ({
    dirname: "",
    upperPage: undefined,
    lowerPage: undefined,
  }),

  created() {
    if (process.env.NODE_ENV == "development") this.dirname = "/";
    window.addEventListener("wheel", this.handleScroll);
    this.$recaptchaInstance.hideBadge();
  },
  destroyed() {
    window.removeEventListener("wheel", this.handleScroll);
  },

  methods: {
    handleScroll(event) {
      let bottomOfWindow =
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop
        ) +
          window.innerHeight ===
        document.documentElement.offsetHeight;

      let TopOfWindow = window.pageYOffset === 0;

      // Scroll to the top
      if (TopOfWindow && event.deltaY < 0 && this.upperPage != undefined) {
        this.$router.push(this.upperPage);
      }
      // Scroll to the bottom
      if (bottomOfWindow && event.deltaY > 0 && this.lowerPage != undefined) {
        this.$router.push(this.lowerPage);
      }
    },
  },
};
