<template>
  <v-app style="background-color: #312450">
    <v-btn
      class="mt-16 mr-16"
      fab
      style="position: fixed; right: 0; z-index: 100"
      x-small
      to="/projects"
    >
      <v-icon x-large color="red">fa-times-circle</v-icon>
    </v-btn>
    <v-container class="mb-16">
      <v-row align="center" justify="center">
        <v-col cols="12" md="12" lg="11" class="mt-16">
          <h2 class="text-h2 mb-8 major">My professional experiences</h2>
          <v-item-group>
            <v-container>
              <v-card
                outlined
                class="mt-16"
                style="background-color: rgba(255, 255, 255, 0)"
              >
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="8">
                    <v-card-text class="justify-center">
                      <p>
                        <b>Company :</b> Co-operators<br />

                        <b>Job Duration:</b> 10 months and still counting :)<br />

                        <b>Project Team:</b> 5 Research And Innovation Analyst,
                        1 Team Director and me, the tech guy<br />

                        <b>My contribution:</b> I am working on a website that
                        stores all Climate apps.<br />

                        The project is confidential so I can't give any
                        Screenshots or Database informations.
                      </p>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" sm="4" height="100%" class="justify-center">
                    <v-img
                      class="image"
                      style="float: right"
                      :src="dirname + 'images/ProfessionalExperiences/Coop.png'"
                      height="auto"
                      width="80%"
                      align-right
                    ></v-img>
                  </v-col>
                </v-row>
              </v-card>

              <v-card
                outlined
                class="mt-16"
                style="background-color: rgba(255, 255, 255, 0)"
              >
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="4" height="100%" class="justify-center">
                    <v-img
                      class="image"
                      :src="dirname + 'images/ProfessionalExperiences/SQLI.jpg'"
                      height="auto"
                      width="80%"
                    ></v-img>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <v-card-text class="justify-center">
                      <p>
                        <b>Company :</b> SQLI<br />

                        <b>Technology Used:</b> JAVA, IBM DB 2, Javascript,
                        Maven, Spring <br />

                        <b>Job Duration:</b> 4 months<br />

                        <b>Project Team:</b> 3 developers and two project
                        leaders<br />

                        <b>My contribution:</b> I was mainly in charge of doing
                        the backend code. Our goal was to upgrade a current
                        software and fix existing bugs.<br />

                        The project is also confidential so I can't give any
                        Screenshots or Database informations.
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>

              <v-card
                outlined
                class="mt-16"
                style="background-color: rgba(255, 255, 255, 0)"
              >
                <v-row align="center" justify="center">
                  <v-col cols="12" sm="8">
                    <v-card-text class="justify-center">
                      <p>
                        <b>Company :</b> Un H'air De Famille<br />

                        <b>Job Duration:</b> 4 months<br />

                        <b>Project Team:</b> 5 students<br />

                        <b>My contribution:</b> I was working on both project
                        listed below, I was also in charge of managing the team
                        and communicating with the company.<br />

                        Both projects are detailed below :
                      </p>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" sm="4" height="100%" class="justify-center">
                    <v-img
                      class="image"
                      style="float: right"
                      :src="dirname + 'images/ProfessionalExperiences/HDF.png'"
                      height="auto"
                      width="80%"
                      align-right
                    ></v-img>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>
          </v-item-group>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>
<script>
export default {
  name: "professional-experiences-component",

  data: () => ({
    dirname: "",
    traductionData: undefined,
  }),

  created() {
    if (process.env.NODE_ENV == "development") this.dirname = "/";
  },
};
</script>

<style lang="scss">
strong,
b {
  color: #ffffff;
  font-weight: bold;
}
p {
  color: rgba(255, 255, 255, 0.55) !important;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12.5pt !important;
  font-weight: normal !important;
  line-height: 1.75 !important;
}

.image {
  border-radius: 0.25em;
  border: 0;
}

h2.major {
  margin: 0 0 1.3em 0;
  position: relative;
  padding-bottom: 0.35em;
}

h2.major:after {
  background-image: -moz-linear-gradient(to right, #5e42a6, #b74e91);
  background-image: -webkit-linear-gradient(to right, #5e42a6, #b74e91);
  background-image: -ms-linear-gradient(to right, #5e42a6, #b74e91);
  background-image: linear-gradient(to right, #5e42a6, #b74e91);
  -moz-transition: max-width 0.2s ease;
  -webkit-transition: max-width 0.2s ease;
  -ms-transition: max-width 0.2s ease;
  transition: max-width 0.2s ease;
  border-radius: 0.2em;
  bottom: 0;
  content: "";
  height: 0.05em;
  position: absolute;
  right: 0;
  width: 100%;
}
</style>
