<template>
  <v-app id="wrapper" nativeOnScroll="{this.handleScroll}">
    <iframe
      class="background"
      allowfullscreen
      :src="dirname + 'background/3dvue.html'"
      @load="bgloaded = true"
    ></iframe>

    <div class="loadingWrap" v-if="!bgloaded">
      <div class="loading">
        <div class="bounceball"></div>
        <div class="text">BACKGROUND LOADING</div>
      </div>
    </div>

    <v-card
      color="transparent"
      outlined
      min-width="200px"
      min-height="50px"
      max-width="550px"
      max-height="400px"
      class="ma-10 ma-lg-16"
    >
      <div class="main">
        <div class="shape-outer heptagon">
          <img
            class="heptagon"
            id="ProfilePhoto"
            :src="dirname + 'images/ProfilePhoto.jpg'"
            alt="Théo GIMENEZ portrait"
          />
        </div>
        <div class="border">
          <div class="inner-cutout">
            <h3 class="knockout">
              Welcome <br />
              User !
            </h3>
          </div>
        </div>
      </div>
    </v-card>

    <v-card
      color="transparent"
      outlined
      class="mb-16 ml-10"
      style="display: inline-block"
      max-width="700px"
    >
      <v-card-title primary-title class="mt-16 text-wrap">
        <h1>
          GIMENEZ <br v-if="$vuetify.breakpoint.width < 365" />
          Théo
        </h1>
      </v-card-title>
      <v-card-text class="mb-3">
        <p
          class="text-h5"
          style="color: rgba(255, 255, 255, 0.55); font-size: 1.25em"
        >
          Welcome on my Portfolio where you will learn more things <br />
          about me and my projects.
        </p>
      </v-card-text>
      <v-card-actions>
        <v-btn to="whoami" outlined rounded> Learn More</v-btn>
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
import scrollManager from "../plugins/scrollManager";
export default {
  name: "home-component",
  mixins: [scrollManager],

  data: () => ({
    dirname: "",
    traductionData: undefined,
    lowerPage: "/whoami",
    bgloaded: false,
  }),
};
</script>

<style lang="scss">
@import "~/src/scss/home.scss";
</style>
