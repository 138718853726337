<template>
  <v-footer dark padless> </v-footer>
</template>

<script>
export default {
  name: "footer-component",
  components: {},

  data: () => ({}),
};
</script>
 