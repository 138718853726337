import Vue from "vue";
import { VueReCaptcha } from "vue-recaptcha-v3";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./plugins/router";
import icons from "@mdi/font/css/materialdesignicons.css";
import axios from "axios";

// Setting language depending of the browser language.
vuetify.framework.lang.current = "en";

Vue.config.productionTip = false;
Vue.use(VueReCaptcha, {
  siteKey: "6LefiWYeAAAAALUZQHoflHsxZIWb0sQoewEjBvju",
  loaderOptions: { autoHideBadge: true },
});
Vue.prototype.$http = axios;

new Vue({
  router,
  vuetify,
  icons,
  render: (h) => h(App),
}).$mount("#app");
