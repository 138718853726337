<template>
  <v-app dark>
    <title>
      This is theo gimenez's portfolio. You will be able to find many differents
      projects, video games or professionnals and scholar studies.
    </title>
    <meta
      name="description"
      content="Théo GIMENEZ's one pager and portfolio where many informations about him, his projects, his studies and his CV can be found"
    />
    <meta name="language" content="English" />
    <app-header />
    <v-main class="pt-0" id="wrapper">
      <v-btn
        :href="dirname + 'CV-Gimenez-Théo.pdf'"
        target="_blank"
        v-if="showButton"
        id="DownloadButton"
        large
      >
        <v-icon class="mr-2"> solid major fa-download </v-icon>
        Download CV
      </v-btn>
      <v-scroll-x-transition
        v-bind:appear="true"
        mode="out-in"
        :hide-on-leave="true"
      >
        <router-view></router-view>
      </v-scroll-x-transition>
      <app-footer />
    </v-main>
  </v-app>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";
export default {
  name: "App",

  components: {
    "app-header": Header,
    "app-footer": Footer,
  },

  data: () => ({
    dirname: "",
    showButton: true,
  }),

  methods: {
    HandleDownloadCV() {
      if (window.location.href.includes("projectlist")) this.showButton = false;
      else this.showButton = true;
    },
  },

  created() {
    this.HandleDownloadCV();
    if (process.env.NODE_ENV == "development") this.dirname = "/";
  },

  watch: {
    $route() {
      this.HandleDownloadCV();
    },
  },
};
</script>

<style lang="scss">
/* Wrapper (main) */
@media screen and (max-width: 1280px) {
  #wrapper {
    margin-left: 12em;
  }
}

@media screen and (max-width: 1280px) {
  #wrapper {
    margin-left: 0;
    padding-top: 3.5em;
  }
}

#DownloadButton {
  position: fixed;
  z-index: 10000;
  right: 0%;
  margin-top: 3%;
  margin-right: 6% !important;
  margin-bottom: 10%;
}

@media screen and (max-width: 900px) {
  #DownloadButton {
    position: fixed;
    z-index: 10000;
    bottom: 0%;
    right: 0%;
    margin-top: 3%;
    margin-right: 6%;
    margin-bottom: 10%;
  }
}

@media screen and (max-width: 736px) {
  #wrapper {
    padding-top: 0;
  }
}
</style>
