<template>
  <v-card clipped fixed id="sidebar">
    <div class="inner">
      <nav>
        <ul>
          <li v-for="item in items" :key="item.title">
            <a
              :href="item.link"
              :class="{
                active: getClass(item),
              }"
              class="scrolly"
              >{{ item.title }}</a
            >
          </li>
        </ul>
      </nav>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "header-component",

  components: {},

  data: () => ({
    items: [
      { title: "Welcome", link: "#/" },
      { title: "Who am I", link: "#/whoami" },
      {
        title: "All my projects",
        link: "#/projects",
      },
      { title: "Get in touch", link: "#/contact" },
    ],
    activeClass: "",
  }),
  watch: {
    $route: {
      handler: function () {
        this.activeClass = window.location.hash;
      },
      immediate: false,
    },
  },
  methods: {
    getClass: function (item) {
      if (item.link == this.activeClass) return true;
      // Exceptions
      if (
        item.link == "#/projects" &&
        (this.activeClass.includes("#/professionalexperiences") ||
          this.activeClass.includes("#/projectlist"))
      )
        return true;
    },
  },
  created() {
    this.activeClass = window.location.hash;
  },
};
</script>

<style lang="scss">
@import "~/src/scss/header.scss";
</style>
