<template>
  <v-app style="background-color: #5e42a6">
    <v-container class="pl-16 mt-16">
      <v-row class="pl-md-16 mt-16" height="40px">
        <div>
          <h2 class="text-h3 mb-8">Get in touch</h2>
          <p style="color: rgba(255, 255, 255, 0.55)">
            Feel free to contact me via this form or using all available
            informations on the right.
          </p>
        </div>
      </v-row>
      <v-row class="ml-md-16" align="center" justify="center">
        <v-col cols="12" md="7" class="pr-16">
          <v-form ref="form" v-model="valid" lazy-validation target="_blank">
            <v-row>
              <v-col class="my-16">
                <v-text-field
                  v-model="name"
                  :counter="20"
                  :rules="nameRules"
                  required
                  outlined
                  label="Name"
                  name="name"
                  id="name"
                ></v-text-field>
              </v-col>
              <v-col class="my-16">
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  label="E-mail"
                  required
                  outlined
                  name="email"
                  id="email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-textarea
              outlined
              label="Message"
              v-model="message"
              :rules="messageRules"
              name="message"
              id="message"
              rows="8"
              required
            ></v-textarea>

            <!-- :disabled="!valid" -->
            <v-btn
              outlined
              rounded
              class="mr-4"
              @click="validate"
              name="Submit"
              value="Submit"
              :disabled="!valid"
            >
              Send message
            </v-btn>
          </v-form>
        </v-col>
        <v-divider v-if="$vuetify.breakpoint.width > 960" vertical></v-divider>
        <v-col cols="12" md="5">
          <v-divider v-if="$vuetify.breakpoint.width <= 960"></v-divider>
          <div class="ml-8">
            <h3 class="text-h4 mt-8 mb-4">Adress</h3>
            <p style="color: rgba(255, 255, 255, 0.55)">
              Chicoutimi, Quebec,<br />
              CANADA
            </p>
            <h3 class="text-h4 mb-3">Email</h3>
            <p>
              <a
                style="color: rgba(255, 255, 255, 0.55)"
                href="mailto:contact-me@theogimenez.fr"
                >contact-me@theogimenez.fr</a
              >
            </p>
            <h3 class="text-h4">Social networks</h3>
            <v-row class="mt-2">
              <v-btn
                icon
                class="ma-3"
                href="https://github.com/TheoGimenez7"
                target="_blank"
              >
                <v-icon large> icon brands fa-github</v-icon>
              </v-btn>
              <v-btn
                icon
                class="ma-3"
                href="https://gitlab.com/Theo_Gimenez74"
                target="_blank"
              >
                <v-icon large> icon brands fa-gitlab</v-icon>
              </v-btn>
              <v-btn
                icon
                class="ma-3"
                href="https://www.linkedin.com/in/th%C3%A9o-gimenez/"
                target="_blank"
              >
                <v-icon large> icon brands fa-linkedin</v-icon>
              </v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      class="ml-16"
      v-model="validdialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card max-width="600">
        <v-toolbar color="primary" dark>Email confirmation</v-toolbar>
        <v-card-text>
          <div class="text-h2 pa-12">Message sent !</div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="validdialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      class="ml-16"
      v-model="wrongdialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card max-width="600">
        <v-toolbar color="error" dark>Email ERROR</v-toolbar>
        <v-card-text>
          <div class="text-h5 pa-12">
            An unknown error occurred ! <br />
            Make sure you are not a Robot 🤖 <br />
            If you are not, please contact me via :<br />
            <a
              style="color: rgba(255, 0, 0, 1)"
              href="mailto:contact-me@theogimenez.fr"
              >contact-me@theogimenez.fr</a
            >
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="wrongdialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import scrollManager from "../plugins/scrollManager";
export default {
  mixins: [scrollManager],
  data: () => ({
    validdialog: false,
    wrongdialog: false,
    valid: false,
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 20) || "Name must be less than 20 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    message: "",
    messageRules: [
      (v) => !!v || "Message is required",
      (v) => (v && v.length >= 10) || "Message must be more than 10 characters",
    ],
    upperPage: "/projects",
  }),

  created() {
    this.$recaptchaInstance.showBadge();
  },

  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.$recaptcha("login").then((token) => {
          const formData = new FormData();
          formData.append("name", this.name);
          formData.append("email", this.email);
          formData.append("message", this.message);
          formData.append("Submit", "Submit");
          formData.append("g-recaptcha-response", token);
          this.$http
            .post("http://theogimenez.fr/sendMail.php", formData, {
              headers: {
                Accept: "application/json",
              },
            })
            .then((response) => {
              if (response.data.status == "SUCCESS") {
                this.validdialog = true;
                this.reset();
                this.resetValidation();
              } else {
                this.wrongdialog = true;
                this.reset();
                this.resetValidation();
              }
            });
        });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>
